<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <nav id="site-navigation"
      class="has-branding main-navigation grid-container nav-align-right sub-menu-right"
      aria-label="Primary" itemtype="https://schema.org/SiteNavigationElement" itemscope>
      <div class="inside-navigation grid-container">
        <div class="navigation-branding">
          <div class="site-logo">
            <a href="/" title="GBWhatsApp" rel="home">
              <img class="header-image is-logo-image" alt="GBWhatsApp" src="../assets/logo.webp"
                title="GBWhatsApp">
            </a>
          </div>
          <p class="main-title" itemprop="headline">
            <a href="/" rel="home">
              GBWhatsApp
            </a>
          </p>
        </div>

        <div id="primary-menu" class="main-nav">
          <ul id="menu-primary-menu" class="menu sf-menu">
            <li id="menu-item-32"
              class="menu-item menu-item-type-post_type menu-item-object-page menu-item-home current-menu-item page_item page-item-13 current_page_item menu-item-32">
              <a href="/" aria-current="page">Home</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a @click="gotodownload()">Download</a>
            </li>
            <li id="menu-item-33"
              class="menu-item menu-item-type-post_type menu-item-object-post menu-item-33">
              <a href="/blogs">Blogs</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main">
            <section id="section-1" class="section-content">
              <h1 class="section-1-blog-head">
                How to use same WhatsApp Account on Different phone?
              </h1>

              <div class="write-by">
                {{ $global.formatTimestamp(new Date()) }} By Aamir Khan
              </div>

              <p class="writter-content">
                If you are using WhatsApp and want to use the same account on a different phone, the
                answer is "no." <br>
                But if you are using GB WhatsApp and want to use the same account on a different
                phone, the answer is "yes."
              </p>

              <div class="lazy-picture-container writer-banner full-width">
                <picture><img width="auto" height="auto" alt="gbwhatsapp chat"
                    src="../assets/blog-1.jpg"></picture>
              </div>

              <h1 class="intro-title blog">
                Link your GBWhatsApp Account to multiple Devices
              </h1>

              <p class="writter-content">
                As people's demand for chat continues to increase, the functions of WhatsApp will
                also increase. However, the official WhatsApp does
                not meet all the needs of users, and some necessary functions do not meet those
                needs. Therefore, a third-party development team
                appears and updates the version of WhatsApp with richer content. This is GBWhatsApp.
              </p>

              <h1 class="intro-title blog">
                Why are Many People Using
                <a href="/" class="jump-url">GB WhatsApp</a>?
              </h1>

              <p class="writter-content">
                Step1: Open GBWhatsApp on your main smartphone. <br>
                Step2: Tap the ⋮ overflow menu.<br>
                Step3: Tap Linked devices.<br>
              </p>

              <div class="lazy-picture-container writer-banner half-width">
                <picture><img width="auto" height="auto" alt="gbwhatsapp chat"
                    src="../assets/blog-2.webp"></picture>
              </div>

              <p class="writter-content">
                Step4: Tap the green Link a device button. <br>
                Step5: Scan the QR code displayed on your other phone.<br>
                Step6: Alternatively, select <strong>Link with phone number instead</strong>. Open
                <a href="https://web.whatsapp.com/">WhatsApp GB</a> on the secondary phone and enter
                your phone number to receive a one-time passcode
                to link both devices.<br>
                Step7: Your secondary phone starts synchronizing your messages.
              </p>

              <h1 class="intro-title blog">
                Enjoy GB WhatsApp from two (or more) phones
              </h1>

              <p class="writter-content">
                GBWhatsApp allows you to utilize GB WhatsApp on multiple phones simultaneously, up
                to a maximum of five devices. The best part is that
                you can use GB WhatsApp on your secondary phone even without a SIM card. With this
                feature, you can fully enjoy the functionalities of
                GB WhatsApp on your secondary device, including sending and receiving messages,
                images, and videos. Additionally, you can make and
                receive voice and video calls through GB WhatsApp on your secondary phone. And if
                you're looking for more, here are some handy tips
                and tricks to enhance your GB WhatsApp experience.
              </p>

              <h1 class="intro-title blog">
                Will my message History appear on all my linked devices?
              </h1>

              <p class="writter-content">
                When you link your primary phone to another device, GBWhatsApp will send an
                encrypted copy of your recent message history. This data
                is stored locally on each device. So you can allow it to copy that for your current
                one. If you do not see an older message on a
                companion device, you should be able to find the message on your primary phone.
              </p>

              <h1 class="intro-title blog">
                GB WhatsApp Download and GB Whatsapp Update
              </h1>

              <p class="writter-content">
                Click on the following button to download the GB WhatsApp
              </p>

              <div
                class="is-vertical is-content-justification-center is-layout-flex wp-container-9 wp-block-buttons">
                <div class="wp-block-button">
                  <a id="dl" class="wp-block-button__link wp-element-button" target="_blank"
                    rel="dl noopener" @click="gotodownload()">Download Page</a>
                </div>
              </div>
            </section>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="#">Privacy Policy</a>
          </div>
          <div class="copyright-bar">
            2022 © All Rights Reserved <strong><a href="/">GBWhatsApp</a></strong>
          </div>
        </div>
      </footer>
    </div>

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Download GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';

export default {
  name: 'Home',
  data () {
    return {
      pageName: 'home',
      bloglist: [
        {
          title: 'GB WhatsApp and WhatsApp spy',
          poster: require('@/assets/blog-1.jpg'),
          desc: 'Many people now use WhatsApp to send videos, photos, audio files, most importantly, photographs. However, as more people use WhatsApp, new needs emerge, such as the need for many accounts to log in at the same time',
          link: '/gbwhatsapp-apk-blog',
        },
      ],
      feature: [
        {
          feature: 'Hide Online Status',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Airplane Mode',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Status Download',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Add Custom Fonts/Stickers',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Media Sharing',
          gb: 'Maximum 200MB',
          whs: 'Maximum 15MB',
        },
        {
          feature: 'Status Character Length',
          gb: 'Maximum 255',
          whs: 'Maximum 139',
        },
        {
          feature: 'Themes Supported',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'DND Mode',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Freeze Last Seen',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Disable Forwarded Tag',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Disable/Customize Calling',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Anti-Delete Status/Messages',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Security Lock',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Fully Customize',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Auto Reply',
          gb: '<div class="check-1"></div>',
          whs: '<div class="check-0"></div>',
        },
        {
          feature: 'Increase Forward Limit',
          gb: 'Maximum 250',
          whs: 'Maximum 10',
        },
        {
          feature: 'Increase Image Share Limit',
          gb: 'Maximum 100',
          whs: 'Maximum 30',
        },
      ],
    };
  },
  mounted () { },
  methods: {
    gotodownload () {
      // const params = new URLSearchParams(window.location.href.search)
      let params = window.location.search;
      window.location.href = '/downloadpage' + params;
    },
    jump (url) {
      window.location.href = url;
    },
  },
};
</script>
